import * as React from "react";
import { graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { MDXProvider } from "@mdx-js/react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SSRProvider from "react-bootstrap/SSRProvider";

import Accordion from "react-bootstrap/Accordion";
import { Celebrating30Years } from "../components/banner";
import DefaultHeader from "../components/default-header";
import Footer from "../components/footer";
import GdprPanel from "../components/gdpr";
import { ImpactScholarshipTopCTA } from "../components/impactScholarshipCTA";
import Navigation from "../components/navigation";
import Seo from "../components/seo";
import StudentProfileVideos from "../components/studentProfileVideos";
import SubNavigation from "../components/subNav";
import AdmissionsApplicationStartForm from "../components/forms/admissionsApplicationStart";
import AdmissionsOverviewBottomMenu from "../components/admissionsOverviewBottomMenu";
import SpeakWithAdmissions from "../components/speakWithAdmissions";

import "../styles/financial-aid.scss";
import NewsletterSignup from "../components/newsletterSignUp";

export default function FinancialAidPage({ data }) {
  const post = data.mdx;
  const shortcodes = {
    Container,
    Row,
    Col,
    Accordion,
    AdmissionsApplicationStartForm,
    AdmissionsOverviewBottomMenu,
    SpeakWithAdmissions,
    StudentProfileVideos,
    Celebrating30Years,
    ImpactScholarshipTopCTA,
  };

  return (
    <>
      <SSRProvider>
        <Navigation />
        <DefaultHeader
          headline={post.frontmatter.headline}
          heroBackground={post.frontmatter.heroBackground}
          heroMobileBackground={post.frontmatter.heroMobileBackground}
          subHeadline={post.frontmatter.subheadline}
        />
        {post.frontmatter.subNav && (
          <div className="fin-aid">
            <SubNavigation type="new" subNav={post.frontmatter.subNav} />
          </div>
        )}
        <MDXProvider components={shortcodes}>
          <MDXRenderer>{post.body}</MDXRenderer>
        </MDXProvider>
        <Footer />
        <GdprPanel />
        <NewsletterSignup />
      </SSRProvider>
    </>
  );
}

export const Head = ({ data }) => (
  <Seo
    title={data.mdx.frontmatter.metadata.Title}
    description={data.mdx.frontmatter.metadata.Description}
    pathname={data.mdx.frontmatter.metadata.Link}
    ogImage={data.mdx.frontmatter.metadata.ogImage}
    cardImage={data.mdx.frontmatter.metadata.cardImage}
  />
);

export const query = graphql`
  query ($slug: String!) {
    mdx(slug: { eq: $slug }) {
      frontmatter {
        heroBackground
        heroMobileBackground
        subheadline
        headline
        title
        metadata {
          Description
          Keywords
          Link
          Title
          ogImage
          cardImage
        }
        subNav {
          text
          url
        }
      }
      body
    }
  }
`;
